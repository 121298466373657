<template>
  <v-app>
    <core-snackbar />

    <v-app-bar app fixed dark color="primary">
      <v-toolbar-title>
        <v-img
          class="pl-0"
          width="100px"
          :src="require('@/assets/images/logo-menu.png')"
        />
      </v-toolbar-title>
    </v-app-bar>

    <v-main class="blue-grey lighten-5" style="min-height: 100vh">
      <v-container fluid fill-height>
        <v-row justify="center">
          <v-col cols="12" md="5">

            <p class="display-1 ma-0 text-center font-weight-bold">
              Bem vindo, entre com seu usuário e senha para continuar
            </p>

            <v-form @submit="login">
              <v-row class="pa-4">
                <v-col>
                  <validation-observer ref="observer">
                    <validation-provider v-slot="{ errors }" vid="username">
                      <v-text-field
                        v-model="form.username"
                        hide-details="auto"
                        label="Usuário"
                        class="mb-4"
                        filled
                        autocomplete="nope"
                        :error-messages="errors"
                        prepend-icon="mdi-account-circle"
                      />
                    </validation-provider>
                    <validation-provider v-slot="{ errors }" vid="password">
                      <v-text-field
                        v-model="form.password"
                        hide-details="auto"
                        type="Password"
                        label="Senha"
                        filled
                        :error-messages="errors"
                        prepend-icon="mdi-lock"
                      />
                    </validation-provider>
                  </validation-observer>
                </v-col>
              </v-row>
              <v-row class="mt-4 px-4 pt-0 pb-1">
                <v-col class="text-right">
                  <v-btn color="primary" :loading="loadingLogin" type="submit">
                    Entrar
                    <v-icon right v-text="'mdi-arrow-right'" />
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex'
import authApi from '@/api/auth'

export default {
  components: {
    CoreSnackbar: () => import('@/components/core/CoreSnackbar'),
  },

  data: () => ({
    loadingLogin: false,
    form: {
      username: null,
      password: null,
    },
  }),

  computed: {},

  methods: {
    ...mapMutations({
      setToken: 'customer/SET_TOKEN',
      setCustomer: 'customer/SET_CUSTOMER',
    }),

    async login(event) {
      try {
        event.preventDefault()
        this.loading = true

        const response = await authApi.loginCustomer(this.form)

        this.setToken(response.data.token)
        this.setCustomer(response.data.user)

        this.$router.push('/acesso-cliente/dashboard')
      } catch (e) {
        if (e.response && e.response.status === 422) {
          return this.$refs.observer.setErrors(
            this.$apiError.fieldsValidation(e),
          )
        }

        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
